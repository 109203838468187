<template>
  <main>
    <section class="application_wrapper" ref="refApplication">
      <div class="application">
        <div class="title_box">
          <h1>스탬프 팝 페스티벌</h1>
          <h1>어떻게 입점하나요<span>?</span></h1>
        </div>
        <div class="sequence_box">
          <div class="sequence_title">
            <img src="@/assets/img/rectangle-48.png" alt="입점순서" />
            <h4>입점순서</h4>
          </div>
          <div class="sequence_contents">
            <div class="sequence_content">
              <span>01</span>
              <p>
                회원가입<br />
                이용권결제
              </p>
            </div>
            <div class="sequence_content">
              <span>02</span>
              <p>
                축제, 스탬프,<br />
                미션 정보입력
              </p>
            </div>
            <div class="sequence_content">
              <span>03</span>
              <p>
                스탬프 팝<br />
                담당자 확인
              </p>
            </div>
            <div class="sequence_content">
              <span>04</span>
              <p>
                스탬프 팝<br />
                플랫폼 셋팅
              </p>
            </div>
            <div class="sequence_content">
              <span>05</span>
              <p>
                축제 일정에<br />
                맞추어 오픈
              </p>
            </div>
          </div>
          <div class="disturbance_box">
            <img src="@/assets/img/rectangle-46.png" alt="약 1~3일 소요" />
            <p>1~3일 소요</p>
          </div>
        </div>
        <div class="notice_box">
          <h3>
            <span> </span>
            확인해주세요.
          </h3>
          <p>
            - 스탬프 팝 플랫폼은 정보입력 후 세팅 완료까지 약 1~3일이
            소요됩니다.<br />
            축제 시작 전 여유를 두고 신청해주세요. 빠른 진행을 원하시는 경우
            고객센터로 문의해주세요.
          </p>
          <p>
            - 업로드한 축제 자료는 스탬프 팝 담당자가 확인한 후 플랫폼 셋팅을
            시작합니다.<br />
            자료 보완이 필요한 경우 회원정보의 연락처로 연락을 드립니다.
          </p>
        </div>
      </div>
    </section>
    <section class="inquiry_wrapper">
      <div class="inquiry">
        <h1>스탬프 팝 입점 문의</h1>
        <h1>
          <a href="tel:02-6337-0310">02-6337-0310</a>이나
          <span class="email" @click="sendEmail"
            >festival@nextinnovation.kr</span
          >으로<br class="br" />
          연락주시면 친절히 상담해드리겠습니다<span>.</span>
        </h1>
      </div>
    </section>
    <section class="appdown_wrapper">
      <div class="link_box">
        <div class="link_box_bg">
          <div class="left"></div>
          <div class="right"></div>
        </div>
        <div class="links_container">
          <div class="download">
            <h3>스탬프 팝 앱 다운받기</h3>
            <div class="app_icon">
              <img src="@/assets/img/app-icon.png" alt="스탬프 팝 앱" />
            </div>
            <div class="links">
              <a
                href="https://apps.apple.com/kr/app/스탬프팝-스탬프투어로-떠나는-국내여행/id1207835232"
                target="_blank"
                class="link"
              >
                <p>앱스토어 다운로드</p>
                <img src="@/assets/img/apple.png" alt="앱스토어 다운로드" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=kr.nextinnovation.stamppop"
                target="_blank"
                class="link"
              >
                <p>구글스토어 다운로드</p>
                <img src="@/assets/img/google.png" alt="구글스토어 다운로드" />
              </a>
            </div>
          </div>
          <div class="question">
            <h3>스탬프 팝 입점 문의하기</h3>
            <div class="chat" @click.prevent.stop="onChannelIO">
              <h4>채팅 문의하기</h4>
              <p>클릭하시면 채팅문의로 바로 연결됩니다.</p>
              <img src="@/assets/img/chatbot.png" alt="채팅 문의하기" />
            </div>
            <div class="email" @click="sendEmail">
              <h4>이메일 문의하기</h4>
              <p>festival@nextinnovation.kr</p>
              <img src="@/assets/img/mail@2x.png" alt="이메일 문의하기" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      meta: {
        title: "스탬프 투어, 스탬프 팝 - 입점절차",
        description:
          "스탬프 투어는 스탬프 팝! 입점 절차를 안내해드립니다. 이미 많은 지역자치단체에서 스탬프 팝을 통해 비대면 여행을 제공하고 있습니다.",
      },
    };
  },
  methods: {
    sendEmail() {
      window.open("mailto:festival@nextinnovation.kr");
    },
    onChannelIO() {
      document.getElementById("show_chatbot").click();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
